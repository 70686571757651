exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-component-tsx": () => import("./../../../src/pages/component.tsx" /* webpackChunkName: "component---src-pages-component-tsx" */),
  "component---src-pages-cotiza-tu-reparacion-tsx": () => import("./../../../src/pages/cotiza-tu-reparacion.tsx" /* webpackChunkName: "component---src-pages-cotiza-tu-reparacion-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nuestros-cursos-tsx": () => import("./../../../src/pages/nuestros-cursos.tsx" /* webpackChunkName: "component---src-pages-nuestros-cursos-tsx" */),
  "component---src-pages-nuestros-servicios-tsx": () => import("./../../../src/pages/nuestros-servicios.tsx" /* webpackChunkName: "component---src-pages-nuestros-servicios-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-sucursales-tsx": () => import("./../../../src/pages/sucursales.tsx" /* webpackChunkName: "component---src-pages-sucursales-tsx" */),
  "component---src-templates-content-tsx": () => import("./../../../src/templates/content.tsx" /* webpackChunkName: "component---src-templates-content-tsx" */)
}

